import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import { Trans } from 'lib/i18n';
import Hero from 'components/card/hero';
import NewsHero from 'components/card/news-hero';

const ActivityStreamItem = ({
  imageSrc,
  linkProps,
  messageType,
  published,
  title,
  isHero,
  isNewsHero,
  ...otherProps
}) => {
  if (isHero) {
    return (
      <Hero
        category={<Trans i18nKey={`common:${messageType}_activity_type`} />}
        date={published}
        imageSrc={imageSrc}
        linkProps={linkProps}
        title={title}
        {...otherProps}
      />
    );
  }

  if (isNewsHero) {
    return (
      <NewsHero
        category={<Trans i18nKey={`common:${messageType}_activity_type`} />}
        date={published}
        imageSrc={imageSrc}
        linkProps={linkProps}
        title={title}
        {...otherProps}
      />
    );
  }

  return (
    <Card
      category={<Trans i18nKey={`common:${messageType}_activity_type`} />}
      date={published}
      imageSrc={imageSrc}
      linkProps={linkProps}
      title={title}
      {...otherProps}
    />
  );
};
ActivityStreamItem.defaultProps = {
  published: null,
  linkProps: null,
  isHero: false,
  isNewsHero: false,
};

ActivityStreamItem.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  linkProps: PropTypes.shape({
    route: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
  }),
  messageType: PropTypes.string.isRequired,
  published: PropTypes.string,
  title: PropTypes.string.isRequired,
  isHero: PropTypes.bool,
  isNewsHero: PropTypes.bool,
};

export default ActivityStreamItem;
