import { AbsoluteContainer, Cushion, Rectangle, Typography } from '@pitchero/react-ui';
import IconWithTheme from 'components/icon-with-theme';
import PlayIcon from 'icons/Play';
import useBreakpoint from 'lib/use-breakpoint';
import PropTypes from 'prop-types';
import React from 'react';
import ActivityStreamItem from '../item';
import MediaOverlay from '../media-overlay';

const VideoMessage = ({ message, stacked, imageProps, ...otherProps }) => {
  const minutes = Math.floor(message.duration / 60);
  const seconds = message.duration - minutes * 60;
  const duration = `${minutes > 9 ? minutes : `0${minutes}`}:${
    seconds > 9 ? seconds : `0${seconds}`
  }`;

  return (
    <ActivityStreamItem
      imageSrc={message.thumbnail}
      isPrivate={message.isPrivate}
      linkProps={{
        route: 'media.video',
        params: { id: message.video_id, _title: message.title },
      }}
      imageProps={{ ...imageProps, alignment: 'center' }}
      duration={message.duration}
      renderAfterImage={(isV2) => {
        if (isV2 && stacked === 'always') {
          const isMobile = useBreakpoint();
          return (
            <MediaOverlay isMobile={isMobile} publishedDate={message.published}>
              <PlayIcon color="white" size={isMobile ? 22 : 36} />
            </MediaOverlay>
          );
        }
        return (
          <AbsoluteContainer bottom={0} left={0} style={{ whiteSpace: 'nowrap' }}>
            <Rectangle
              fill="primary"
              radius={stacked === 'always' ? 'roundedTopRight' : 'roundedTopRightAndBottomLeft'}
              responsive={[
                {
                  minWidth: 'tab',
                  props: {
                    radius:
                      stacked === 'never' ? 'roundedTopRightAndBottomLeft' : 'roundedTopRight',
                  },
                },
              ]}
            >
              <Cushion all="xsmall" style={{ display: 'inline-block' }} component="div">
                <IconWithTheme>
                  <PlayIcon color="white" preset="name" />
                </IconWithTheme>
              </Cushion>
              <Cushion
                vertical="xsmall"
                right="xsmall"
                style={{ display: 'inline-block' }}
                component="div"
              >
                <Typography color="primaryContrast" preset="name">
                  {duration}
                </Typography>
              </Cushion>
            </Rectangle>
          </AbsoluteContainer>
        );
      }}
      messageType={message.type}
      published={message.published}
      stacked={stacked}
      title={message.title}
      {...otherProps}
    />
  );
};

VideoMessage.defaultProps = {
  stacked: 'always',
  imageProps: {},
};

VideoMessage.propTypes = {
  message: PropTypes.shape({
    duration: PropTypes.number,
    isPrivate: PropTypes.bool,
    published: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    video_id: PropTypes.number.isRequired,
  }).isRequired,
  stacked: PropTypes.string,
  imageProps: PropTypes.shape(),
};

export default VideoMessage;
