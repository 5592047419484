import { Image, Typography } from '@pitchero/react-ui';
import ClubLink from 'components/club-link';
import PublishedDate from 'components/published-date';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CategoryTag from './category';
import InfoLine from './info-line';

const HeroContainer = styled.div`
  height: 100%;
  display: grid;
  position: relative;
  grid-template-rows: 1fr;
  max-width: 1366px;
  margin: 0 auto;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  color: white;
  ${(props) => {
    return `
      background-color: ${props.bgColor};
      color: ${props.textColor};
    `;
  }};
`;

const HeroBody = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 33%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  padding: 32px;
`;

const HeroInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5%;
  padding: 32px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0) 100%);
  display: flex;
  gap: 20px;
  align-items: center;
`;

const HeroTitle = styled.div`
  font-family: anton;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: 0.1rem;
  // Use resolution media query on higher pixel ratio devices for letter spacing
  @media (min-resolution: 3x) {
    letter-spacing: normal;
  }
`;

const NewsHero = ({ category, date, imageSrc, title, linkProps, tagline }) => {
  return (
    <ClubLink {...linkProps}>
      <HeroContainer className="cardHighlightSlow">
        <Image
          src={imageSrc}
          alt={title}
          shimmerOnLoad
          loading="eager"
          width={980}
          height={550}
          style={{ width: '100%', height: 'auto', aspectRatio: '16 / 9' }}
        />
        <HeroBody>
          <HeroTitle>{title}</HeroTitle>
          {tagline && (
            <Typography preset="description" size={16}>
              {tagline}
            </Typography>
          )}
        </HeroBody>
        <HeroInfo>
          <CategoryTag category={category} />
          <InfoLine size={15}>
            <PublishedDate date={date} />
          </InfoLine>
        </HeroInfo>
      </HeroContainer>
    </ClubLink>
  );
};

NewsHero.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  linkProps: PropTypes.shape({
    route: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
  }),
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string,
};

NewsHero.defaultProps = {
  category: null,
  date: null,
  linkProps: null,
  tagline: null,
};

export default NewsHero;
